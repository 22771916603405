import React, { useEffect, useRef, useState } from "react";
import videojs from "video.js";
import { styled } from '@mui/material/styles';
import "video.js/dist/video-js.css";
import {
  registerIVSQualityPlugin,
  registerIVSTech,
} from 'amazon-ivs-player';

import wasmBinaryPath from 'amazon-ivs-player/dist/assets/amazon-ivs-wasmworker.min.wasm';
import wasmWorkerPath from 'amazon-ivs-player/dist/assets/amazon-ivs-wasmworker.min.js';

const PREFIX = 'IVSVideoPlayer';

const classes = {
  root: `${PREFIX}-root`,
  card: `${PREFIX}-card`,
  continue: `${PREFIX}-continue`,
  continueBack: `${PREFIX}-continueBack`,
  margin: `${PREFIX}-margin`,
  resume: `${PREFIX}-resume`,
  youtube: `${PREFIX}-youtube`,
  player: `${PREFIX}-player`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ aspectRatio }) => ({
  height: '100%',
  width: '100%',
  [`& .${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  },
  [`& .${classes.card}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem 2rem 2rem 2rem'
  },
  [`& .${classes.continue}`]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '1rem 2rem 1rem 2rem',
    zIndex: 1002,
    width: '35%',
    minWidth: '160px',
    maxWidth: '260px'
  },
  [`& .${classes.continueBack}`]: {
    zIndex: 1001,
    position: 'absolute',
    height: '100%',
    width: '100%',
    backdropFilter: 'blur(12px)'
  },
  [`& .${classes.margin}`]: {
    margin: '1rem'
  },
  [`& .${classes.resume}`]: {
    position: 'absolute',
    height: '100vh',
    width: '100vw',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    // backgroundColor: '#151515'
  },
  [`& .${classes.youtube}`]: {
    maxWidth: aspectRatio ? `${1 / aspectRatio * 100}vh` : 0,
    maxHeight: aspectRatio ? `${aspectRatio * 100}vw` : 0,
    width: aspectRatio ? '100%' : 0,
    height: aspectRatio ? '100%' : 0,
    flex: 1
  },
  [`& .${classes.player}`]: {
    width: '100%',
    height: '100%',
    pointerEvents: 'none'
  },
  [`& .${classes.player} iframe`]: {
    width: '100%',
    height: '100%',
  }
}));

const createAbsolutePath = (assetPath) => new URL(assetPath, document.URL).toString();

const DEFAULT_STREAM =
  "https://fcc3ddae59ed.us-west-2.playback.live-video.net/api/video/v1/us-west-2.893648527354.channel.DmumNckWFTqz.m3u8";

// import * as workerTimers from 'worker-timers'

// document.pictureInPictureEnabled = false

let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') {
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}


const IVSVideoPlayer = React.memo(({ videoRef, style, fluid, fill, fullscreen, videoId, onTimeUpdate, onStarted, onMetadataLoaded, onLoaded, onVideoLoaded, onReady, onPlay, onBuffer, onLoadingVideo, onVisiblityChange, onFinished, onVideoError, isLive, liveStreamStatus, sessionComplete, videoState, playbackStartTime, isMuted }) => {
  const [aspectRatio, setAspectRatio] = useState()
  const [loaded, setLoaded] = useState(false)
  // const [resuming, setResuming] = useState(alreadyStarted)

  const visibilityChangeFn = () => { }

  const playerRef = useRef()
  // const loadedRef = useRef(false)
  const isPlaying = useRef(false)
  const stateSet = useRef(false)
  const hasStarted = useRef(false)
  const startTimeRef = useRef()
  const completeRef = useRef()
  // const timerRef = useRef()
  // const fadeRef = useRef()
  // const volumeRef = useRef(100)

  useEffect(() => {
    if ((document[hidden] || !document.hasFocus()) && onVisiblityChange) onVisiblityChange()
  }, [onVisiblityChange])

  useEffect(() => {
    startTimeRef.current = playbackStartTime
  }, [playbackStartTime])

  useEffect(() => {
    if (sessionComplete && playerRef.current) {
      console.log('ATTEMPTING TO STOP VIDEO')
      hasStarted.current = false
      playerRef.current.pause()
    }
    completeRef.current = sessionComplete
  }, [sessionComplete])

  useEffect(() => {
    registerIVSTech(videojs, {
      wasmWorker: createAbsolutePath(wasmWorkerPath),
      wasmBinary: createAbsolutePath(wasmBinaryPath),
    });
    registerIVSQualityPlugin(videojs);

    const videoElement = videoRef.current;

    // Initialize the Video.js player with IVS configuration
    playerRef.current = videojs('video-js-live', {
      techOrder: ["AmazonIVS"],
      // controls: true,
      autoplay: true
    }, () => {
      const events = playerRef.current.getIVSEvents();
      const ivsPlayer = playerRef.current.getIVSPlayer();
      // console.log('ivsPlayer:', ivsPlayer)
      ivsPlayer.addEventListener(events.PlayerEventType.INITIALIZED, () => {
        // console.log('IVS Player INITIALIZED')
        setAspectRatio(ivsPlayer.getDisplayHeight() / ivsPlayer.getDisplayWidth())
        if (onMetadataLoaded) onMetadataLoaded(ivsPlayer.getDisplayHeight() / ivsPlayer.getDisplayWidth())
      });

      ivsPlayer.addEventListener(events.PlayerState.ENDED, () => {
        // console.log('IVS Player has ended')
        if (hasStarted.current) {
          playerRef.current.seekTo(startTimeRef.current || 0)
          playerRef.current.pause()
        } else if (!stateSet.current) {
          playerRef.current.pause()
        }
      });
      ivsPlayer.addEventListener(events.PlayerState.PLAYING, () => {
        // console.log('IVS Player is playing')
        if (!isPlaying.current) {
          // console.log('IVS STARTING PLAYBACK', hasStarted.current)
          // if (!hasStarted.current && !startTimeRef.current) hasStarted.current = true
          // if (!hasStarted.current) playerRef.current.seekTo(startTimeRef.current || 0)
          playerRef.current.play()
        }
      });
      ivsPlayer.addEventListener(events.PlayerEventType.SYNC_TIME_UPDATE, (e) => {
        // console.log('IVS Player TIME_UPDATE', e)
        // playerRef.current.overlay({
        //   content: '',
        //   class: classes.customOverlay,
        //   overlays: [{
        //     align: "center",
        //     content: overlayFn(wm, playerRef.current.tech_.el_.videoHeight, playerRef.current.tech_.el_.videoWidth, Math.floor(Math.random() * 10000000)),
        //     start: 'play',
        //     end: 'ended'
        //   }]
        // });
        if (onTimeUpdate) onTimeUpdate(e)
      });
      ivsPlayer.addEventListener(events.PlayerState.IDLE, () => {
        // console.log('IVS Player is paused/idle')
        if (isPlaying.current) playerRef.current.pause()
      });
      playerRef.current.src(videoId)
      playerRef.current.play()
    });
    if (videoRef) videoRef.current = playerRef.current //to get time data
    playerRef.current.enableIVSQualityPlugin();
    stateSet.current = true

    onReady && onReady()
  }, [loaded, videoState, videoId, isLive, liveStreamStatus, onBuffer, onLoaded, onMetadataLoaded, onPlay, onReady, onStarted, onFinished, onVideoError, videoRef])

  useEffect(() => {

  }, [videoId, isLive, liveStreamStatus, onBuffer, onLoaded, onMetadataLoaded, onPlay, onReady, onStarted, onFinished, onVideoError, videoRef])

  useEffect(() => {
    if (playerRef.current) {
      if (isMuted) {
        playerRef.current.muted(true)
      } else {
        playerRef.current.muted(false)
      }
    }
  }, [isMuted])


  return (
    (<Root aspectRatio={aspectRatio}>
      <div style={{height: '100%', width: '100%'}} className="video-container">
        <video
          style={{height: '100%', width: '100%'}}
          id="video-js-live"
          ref={videoRef}
          className="video-js vjs-big-play-centered"
          autoPlay
        />
      </div>
    </Root>)
  );
})

export default IVSVideoPlayer;
